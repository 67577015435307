/* eslint-disable */
import React from 'react';
import Img from "gatsby-image";

class ImageLeftTextRightBackground extends React.Component {
  render() {
    return (
      <div className="image-left-text-right-with-background">
        <div className="image-left-text-right-with-background-image">
          { (this.props.data.relationships.field_image && this.props.data.relationships.field_image.localFile) ?
            <Img sizes={this.props.data.relationships.field_image.localFile.childImageSharp.sizes} fadeIn={false} alt="" />
          : null }
        </div>

          <div className="image-left-text-right-with-background-text reverse">
          <div className="image-left-text-right-with-background-text-title intro-bold blue-text">{this.props.data.field_title}</div>
          <div className="image-left-text-right-with-background-text-body body-text" dangerouslySetInnerHTML={{__html: this.props.data.field_body_text.value }}></div>
          <div className="image-left-text-right-with-background-text-list">
            {this.props.data.relationships.field_bullet_points.map(bullet => {
              return (
                <div key={bullet.id} className="image-left-text-right-with-background-text-list-item">
                  <div className="text-list-item-number bold-label" dangerouslySetInnerHTML={{__html: bullet.field_bullet_points_left.value }}></div>
                  <div className="text-list-item-text section-body-text">{bullet.field_bullet_points_right}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default ImageLeftTextRightBackground
